import React, { useState, useEffect } from "react";

import * as Survey from "survey-react-ui";
//import axios from "axios";
import "survey-core/defaultV2.min.css";
//import myData from "../../data/QData.json";
import "./Ques.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { Link, useParams, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";

import { addDays } from "@progress/kendo-date-math";

const SurveyComponent = () => {
  const { patient } = useAuthContext();

  function isEmpty(obj) {
    for (const property in obj) {
      return false;
    }
    return true;
  }
  let userData = {};
  if (!patient) {
    userData = patient;
  }

  let userEmail = userData?.email;
  let userId = userData?._id;

  var jsonData = [];
  let newFinalQnsJsonStr = "";
  let newFinalQnsG = [];
  let sectionListKeys = [];
  let pmSectionListKeys = [];
  let sectionList = [];
  let pmSectionList = [];
  let tmpArrKey = "";

  const { qID } = useParams();

  const navigate = useNavigate();

  let qnID = qID;

  const [quesss, setQuesss] = useState(null);
  const [curQues, setCurQues] = useState(null);
  const [quesId, setQuesId] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [questions, setQuestions] = useState(null);
  const [patientMeta, setPatientMeta] = useState([]);
  const [adMap, setAdMap] = useState([]);
  const [content, setContent] = useState([]);
  const [termsConditions, setTermsConditions] = useState([]);
  const [thankMsg, setThankMsg] = useState([]);

  let survey = new Survey.Model();
  const [surveyContainer, setSurveyContainer] = useState("");
  const [loading, setLoading] = useState(false);

  const surveyCalc = async () => {
    const ptDets = {
      questionnnaire_type: qnID,
      psGend: patient.patient.gender,
    };
    const response_qt = await fetch("/server/patient/getQuesByType", {
      method: "POST",
      body: JSON.stringify(ptDets),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${patient.token}`,
      },
    });
    const json_qt = await response_qt.json();

    const jrDets = {
      jrPrd: json_qt.quesPeriod,
      pattID: patient.patient._id,
      qType: qnID,
    };
    const jrappr = await fetch("/server/patient/checkForJrAprvl", {
      method: "POST",
      body: JSON.stringify(jrDets),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${patient.token}`,
      },
    });
    const apprJson = await jrappr.json();

    //console.log(apprJson.lastDate)

    if (apprJson.lastDate !== null) {
      var dateFormat_qqq = new Date(apprJson.lastDate);
      const rrr = addDays(dateFormat_qqq, parseInt(json_qt.quesPeriod));

      // console.log(rrr)

      if (rrr > new Date()) {
        navigate("/");
      }
    }

    jsonData = json_qt.surveyJson;

    var json = {
      title: json_qt.title,

      showProgressBar: "bottom",
      // showTimerPanel: "top",
      goNextPageAutomatic: false,
      //maxTimeToFinish: 900,
      firstPageIsStarted: true,
      showQuestionNumbers: "off",
      pages: jsonData, //[finalQnsArr],
      completedHtml:
        "<div style='max-width: 550px;margin: 0 auto;'>" +
        json_qt.thanksCont +
        "</div>",
      // "<h3>Thank you!</h3><p style='max-width: 550px;margin: 0 auto;'>We greatly appreciate your time and trust in our survey.<br /><br />Your answers have been recorded. Rest assured that your responses are safe, secure and will be kept anonymous.<br /><br />Our team of experts will evaluate your answers and get back to you with your Personalized Wellness Report.This report will include your state of wellness across 6 dimensions and a few suggested next steps for your consideration.<br /><br />The information in this wellness report is based on the answers provided by the respondent. Please consult your local doctor before using this information to treat your condition.<br /><br /><br /><br /></p><br /></div>",
    };

    //console.log(json);
    survey.clear();

    survey = new Survey.Model(json);
    setSurveyContainer(() => {
      return <Survey.Survey model={survey} />;
    });

    survey.onComplete.add(function (sender) {
      let surveyAnsList = sender.data;

      const svDets = {
        aidqnid: json_qt.quesID,
        answerL: json_qt.aList,
        surveySubmission: surveyAnsList,
        userid: patient.patient._id,
        userFname: patient.patient.firstname,
        userLname: patient.patient.lastname,
        userEmail: patient.patient.email,
      };
      const surveyPost = fetch("/server/patient/postSurvey", {
        method: "POST",
        body: JSON.stringify(svDets),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${patient.token}`,
        },
      });
    });
  };

  useEffect(() => {
    surveyCalc();
  }, []);

  return (
    <>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {surveyContainer}
      <div id="surveyResult"></div>
    </>
  );
};

export default SurveyComponent;
