import React from "react";
import { useState, useEffect } from "react";

import Typography from "@mui/material/Typography/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuthContext } from "../hooks/useAuthContext";

const Welcome = () => {
  const { patient } = useAuthContext();

  const navigate = useNavigate();

    console.log(patient.patient.gender)
  useEffect(() => {
    if (!patient.patient.gender || patient.patient.gender === "") {
      navigate("/userprofile");
    }
  });

  return (
    <>
      <Box
        elevation={3}
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: 5,
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="textPrimary"
        >
          Welcome to AYANA
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          A DIGITAL HEALTH COMPANY
        </Typography>

        <img
          src="/ayanl.jpeg"
          alt="Ayana"
          title="Ayana"
          style={{ maxWidth: "150px", borderRadius: "25px" }}
        />

        <Typography
          component="h6"
          variant="h6"
          sx={{ mb: 5, pt: 5, fontWeight: "normal" }}
        >
          You are logged in as{" "}
          {patient.patient.firstname + " " + patient.patient.lastname}
        </Typography>

        <Grid
          style={{ maxWidth: "800px", margin: "0 auto", width: "100%" }}
          sx={{ justifyContent: "center", py: 2, ml: 0 }}
          container
          spacing={2}
        >
          <Grid
            xs={12}
            sm={12}
            lg={6}
            item
            spacing={2}
            style={{ padding: "8px" }}
          >
            <Card
              style={{
                backgroundColor: "rgb(255, 255, 255);",
                minWidth: "auto",
                width: "100%",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                padding: "15px 0",
                textAlign: "left",
              }}
            >
              <CardContent style={{ padding: "15px 30px" }}>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  style={{ fontWeight: "bolder", color: "rgb(0, 0, 0)" }}
                >
                  Journey
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    fontWeight: "bolder",
                    color: "rgb(0, 0, 0)",
                    padding: "15px 0",
                  }}
                >
                  My Body Signals
                </Typography>
              </CardContent>
              <CardActions style={{ padding: "0 30px 15px" }}>
                <Button
                  component={Link}
                  to={"/QuesList/wellness/"}
                  variant="contained"
                  style={{
                    backgroundColor: "rgb(243, 244, 246)",
                    marginBottom: "0px",
                    color: "#000",
                  }}
                >
                  Proceed
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid
            xs={12}
            sm={12}
            lg={6}
            item
            spacing={2}
            style={{ padding: "8px" }}
          >
            <Card
              style={{
                backgroundColor: "rgb(255, 255, 255);",
                minWidth: "auto",
                width: "100%",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                padding: "15px 0",
                textAlign: "left",
              }}
            >
              <CardContent style={{ padding: "15px 30px" }}>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  style={{ fontWeight: "bolder", color: "rgb(0, 0, 0)" }}
                >
                  Journey
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    fontWeight: "bolder",
                    color: "rgb(0, 0, 0)",
                    padding: "15px 0",
                  }}
                >
                  Auto Immune Propensity
                </Typography>
              </CardContent>
              <CardActions style={{ padding: "0 30px 15px" }}>
                {/* <Button
                    component={Button}
                    disabled={true}
                    variant="contained"
                    style={{
                      backgroundColor: "rgb(243, 244, 246)",
                      marginBottom: "0px",
                      color: "#000",
                    }}
                  >
                    Coming Soon
                  </Button> */}
                <Button
                  component={Link}
                  to={"/QuesList/autoimmune-propensity/"}
                  variant="contained"
                  style={{
                    backgroundColor: "rgb(243, 244, 246)",
                    marginBottom: "0px",
                    color: "#000",
                  }}
                >
                  Proceed
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Welcome;
