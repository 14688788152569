import * as React from "react";
import { Typography, Grid, Container, Box, Link } from "@mui/material/";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.ayana.ai/">
        AYANA
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
export default function Footer() {
  return (
    <Box sx={() => ({ backgroundColor: "#f1ffff" })}>

      <Container>
        <Grid container spacing={2} sx={{ ml: 0 }}>
          <Grid xs={12} sm={12} md={12} >
            {/* <FacebookIcon sx={{ pt: 3, pr: 3 }} />
            <TwitterIcon /> */}
            <Grid sx={{ py: 3 }}>
              {" "}
              <Typography variant="h6" align="center" gutterBottom>
                Powered by <b>AYANA</b> , your Wellness companion!
              </Typography>
              <Typography level="body3" sx={{ ml: "auto" }}>
                <Copyright />
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={4} sm={4} md={2}></Grid>
          <Grid xs={6} sm={8} md={4}></Grid>
          <Grid></Grid>
        </Grid>
      </Container>
    </Box>
  );
}
