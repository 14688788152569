import { createContext, useReducer, useEffect } from "react"

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { patient: action.payload, status: 'loggedIn' }
        case 'LOGOUT':
            return { patient: null, status: 'loggedOut' }
        default:
            return state
    }
}

export const AuthContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(authReducer, {
        patient: JSON.parse(localStorage.getItem('patient'))
    })

    useEffect(() => {
        const patient = JSON.parse(localStorage.getItem('patient'))

        if (patient) {
            dispatch({ type: 'LOGIN', payload: patient })
        }

        if (!patient) {
            dispatch({ type: 'LOGOUT' })
        }
    }, [])

    // console.log("AuthContext state: ", state)

    return (
        <AuthContext.Provider value={{ ...state, dispatch }}>
            {children}
        </AuthContext.Provider>
    )

}