import './App.css';
import Routepath from './route';

function App() {
  return (
    <div>
      <Routepath />
    </div>
  );
}

export default App;