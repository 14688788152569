import React from "react";
import Home from "./Home";
import { Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import UserProfile from "./pages/UserProfile";
import Welcome from "./pages/Welcome";
import QuesList from "./pages/QuesList";
import Report from "./pages/Ques/Report";
import ReportOfAI from "./pages/Ques/ReportOfAI";
import Questionnaire from "./pages/Ques/QuesServey";
import AutoImmune from "./pages/Ques/AutoImmune";

import { useAuthContext } from './hooks/useAuthContext'

function Routepath() {

  const { patient } = useAuthContext()

  return (

    <Routes>
      <Route path="/" element={<Layout />}>

        <Route
          path="/"
          element={!patient ? <Home /> : <Welcome />}
        />
        <Route
          path="/signin"
          element={!patient ? <SignIn /> : <Welcome />}
        />
        <Route
          path="/signup"
          element={!patient ? <SignUp /> : <Welcome />}
        />

        <Route
          path="/welcome"
          element={patient ? (patient.patient.gender ? <Welcome /> : <UserProfile />) : <SignIn />}
        />

        <Route
          path="/userprofile"
          element={patient ? <UserProfile /> : <SignIn />}
        />

        <Route
          path="/QuesList/:qtype"
          element={patient ? (patient.patient.gender ? <QuesList /> : <UserProfile />) : <SignIn />}
        />

        <Route
          path="/Questionnaire/:qID"
          element={patient ? (patient.patient.gender ? <Questionnaire /> : <UserProfile />) : <SignIn />}
        />

        <Route
          path="/Report/:id"
          element={patient ? (patient.patient.gender ? <Report /> : <UserProfile />) : <SignIn />}
        />
              
        <Route
          path="/autoImmune-propensity"
          element={patient ? <AutoImmune /> : <SignIn />}
        />
              
        <Route
          path="/ReportOfAI/:id"
          element={patient ? (patient.patient.gender ? <ReportOfAI /> : <UserProfile />) : <SignIn />}
        />

      </Route>
    </Routes>

  );
}
export default Routepath;