import React, { useState, useEffect } from "react";

import * as Survey from "survey-react-ui";
//import axios from "axios";
import "survey-core/defaultV2.min.css";
//import myData from "../../data/QData.json";
import "./Ques.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { Link, useParams, useNavigate } from "react-router-dom";
import { useAuthContext } from '../../hooks/useAuthContext'

import { addDays } from '@progress/kendo-date-math';

const AutoImmune = () => {

    const { patient } = useAuthContext()

//    function isEmpty(obj) {
//        for (const property in obj) {
//            return false;
//        }
//        return true;
//    }
//    let userData = {};
//    if (!patient) {
//        userData = patient;
//    }
//
//    let userEmail = userData?.email;
//    let userId = userData?._id;
//
//    var jsonData = [];
//    let newFinalQnsJsonStr = "";
//    let newFinalQnsG = [];
//    let sectionListKeys = [];
//    let pmSectionListKeys = [];
//    let sectionList = [];
//    let pmSectionList = [];
//    let tmpArrKey = "";
//
//    const { qID } = useParams()
//
//    const navigate = useNavigate()
//
//    let qnID = qID
//
//    let survey = new Survey.Model()
//    const [surveyContainer, setSurveyContainer] = useState("")
//    const [loading, setLoading] = useState(false)
//
//    const surveyCalc = async () => {
//
//        const ptDets = { questionnnaire_type: qnID, psGend: patient.patient.gender }
//        const response_qt = await fetch('/server/patient/getQuesByType', {
//            method: 'POST',
//            body: JSON.stringify(ptDets),
//            headers: {
//                'Content-Type': 'application/json',
//                'Authorization': `Bearer ${patient.token}`
//            }
//        })
//        const json_qt = await response_qt.json();
//
//        const jrDets = { jrPrd: json_qt.quesPeriod, pattID: patient.patient._id }
//        const jrappr = await fetch('/server/patient/checkForJrAprvl', {
//            method: 'POST',
//            body: JSON.stringify(jrDets),
//            headers: {
//                'Content-Type': 'application/json',
//                'Authorization': `Bearer ${patient.token}`
//            }
//        })
//        const apprJson = await jrappr.json();
//
//        //console.log(apprJson.lastDate)
//
//        if (apprJson.lastDate !== null) {
//            var dateFormat_qqq = new Date(apprJson.lastDate);
//            const rrr = addDays(
//                dateFormat_qqq,
//                parseInt(json_qt.quesPeriod)
//            );
//
//            // console.log(rrr)
//
//            if (rrr > new Date()) {
//                navigate('/')
//            }
//        }
//
//        jsonData = json_qt.surveyJson;
//
//        var json = {
//            title: "State of Wellness Report.",
//
//            showProgressBar: "bottom",
//            // showTimerPanel: "top",
//            goNextPageAutomatic: false,
//            //maxTimeToFinish: 900,
//            firstPageIsStarted: true,
//            showQuestionNumbers: "off",
//            pages: jsonData, //[finalQnsArr],
//            completedHtml:
//                "<div style='max-width: 550px;margin: 0 auto;'>" +
//                json_qt.thanksCont +
//                "</div>",
//        };
//
//
//        //console.log(json);
//        survey.clear();
//
//        survey = new Survey.Model(json);
//        setSurveyContainer(() => {
//            return <Survey.Survey model={survey} />;
//        });
//
//        survey.onComplete.add(function (sender) {
//
//
//            let surveyAnsList = sender.data;
//
//            const svDets = { aidqnid: json_qt.quesID, answerL: json_qt.aList, surveySubmission: surveyAnsList, userid: patient.patient._id }
//            const surveyPost = fetch('/server/patient/postSurvey', {
//                method: 'POST',
//                body: JSON.stringify(svDets),
//                headers: {
//                    'Content-Type': 'application/json',
//                    'Authorization': `Bearer ${patient.token}`
//                }
//            })
//
//        });
//
//    }
//
//
//  
//    useEffect(() => {
//        surveyCalc();
//    }, [])

    return (
//            {loading ? (
//                <Backdrop
//                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
//                    open={true}
//                >
//                    <CircularProgress color="inherit" />
//                </Backdrop>
//            ) : (
//                ""
//            )}
            <div id="surveyResult"></div>
    )

}

export default AutoImmune