import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

import { Link } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext'
import { useLogout } from './hooks/useLogout'
import { Container } from '@mui/material';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function Header() {

  const { patient } = useAuthContext()
  const { logout } = useLogout()

  const [anchorEl, setAnchorEl] = React.useState(false);

  const handleLogout = () => {
    logout()
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  return (
    <Box sx={{ flexGrow: 1 }} >
      <AppBar position="static" color='primary' sx={() => ({ backgroundColor: '#118080' })}>
        <Container>
          <Toolbar variant="dense">

            <img src='/ayana-logo.png' alt="" style={{ display: 'flex', padding: '10px', width: "100px", height: "auto", maxWidth: "50px" }} />

            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              <>
                <Link to="/" style={{ textDecoration: 'none', color: '#fff' }}>
                  AYANA.AI
                </Link>
              </>
            </Typography>
            {!patient ?
              <>
                <Button component={Link} to={'/signin/'} color="inherit">Sign In</Button>
                <Button component={Link} to={'/signup/'} color="inherit">Sign Up</Button>
              </>
              :
              <>
                <Avatar
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  alt={patient.patient.firstname}
                  src={patient.patPicture}
                />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem  onClick={handleClose}>
                    <Link to="/">Home</Link>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
                {/* <Button onClick={handleClick} color="inherit">Logout</Button> */}
              </>
            }
          </Toolbar>
        </Container>
      </AppBar>
    </Box >
  );
}
