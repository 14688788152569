import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Outlet } from 'react-router-dom';
import { Container } from "@mui/material/";
const Layout = () => {
  return (
    <>
      <Header />
      <Container sx={{ minHeight: 'calc(100vh - 210px)', mt: 5 }}>
        <Outlet />
      </Container>
      <Footer />
    </>
  )
}

export default Layout
