import { useState } from "react"
import { useAuthContext } from "./useAuthContext"

export const useRegister = () => {
    const [error, setError] = useState(null)
    const [emptyFields, setEmptyFields] = useState([])
    const [isLoading, setIsLoading] = useState(null)
    const { dispatch } = useAuthContext()

    const signup = async (firstname, lastname, email, password) => {
        setIsLoading(true)
        setError(null)
        setEmptyFields([])

        console.log('test useRegister');

        const response = await fetch('/server/patient/register', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ firstname, lastname, email, password })
        })
        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)
            setEmptyFields(json.emptyFields)
        }
        if (response.ok) {
            // save the patient to local storage (i.e., save jwt in browser)
            localStorage.setItem('patient', JSON.stringify(json))

            // update the AuthContext
            dispatch({ type: 'LOGIN', payload: json })

            setIsLoading(false)
        }
    }

    return { signup, isLoading, error, emptyFields }
}

