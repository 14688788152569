import React, { Component } from "react";
import { useState, useEffect } from "react";

import Typography from "@mui/material/Typography/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { Link, useParams } from "react-router-dom";

import { useAuthContext } from "../hooks/useAuthContext";

//import Questionnaire from '../pages/Ques/QSurvey'

import { addDays } from "@progress/kendo-date-math";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "date", label: "Date", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 100 },
];

const btnStyle = {
  color: "#9195A0",
  backgroundColor: "transparent",
  border: "2px solid #9195A0",
  padding: "8px 12px",
  borderRadius: 4,
  cursor: "default",
};

const textBBtn = {
  margin: "0",
  fontSize: "12px",
  position: "absolute",
  left: "0",
  right: "0",
  top: "calc(100% + 5px)",
  cursor: "default",
};

const QuesList = () => {
  const { patient } = useAuthContext();

  const { qtype } = useParams();

  const [quesss, setQuesss] = useState(null);
  const [curQues, setCurQues] = useState(null);
  const [quesId, setQuesId] = useState(null);
  const [quesEndDate, setQuesEndDate] = useState(null);
  const [quesEnable, setQuesEnable] = useState(false);
  const [daysYet, setDaysYet] = useState(0);
  const [nextDue, setnextDue] = useState("");
  const [getMon, setGetMon] = useState(0);
  const [quesCals, setQuesCals] = useState([]);
  const [yearsIn, setYearsIn] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchScoreCards = async () => {
      const ptID = { psUid: patient.patient._id,q_type:qtype };
      const response = await fetch("/server/patient/getAllScoreCards", {
        method: "POST",
        body: JSON.stringify(ptID),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${patient.token}`,
        },
      }).then((response) => response.json());
      setQuesss(response);

      const ptDets = {
        questionnnaire_type: qtype,
        psGend: patient.patient.gender,
      };
      const response_qt = await fetch("/server/patient/getQuesByTypeToTrack", {
        method: "POST",
        body: JSON.stringify(ptDets),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${patient.token}`,
        },
      }).then((response) => response.json());
      setCurQues(response_qt);

      if (Object.keys(response).length !== 0) {
        let nlen = Object.keys(response).length;
        var dateFormat_qqq = new Date(parseInt(response[nlen - 1].createdAt));
        const rrr = addDays(dateFormat_qqq, parseInt(response_qt.ques_period));
        setQuesEndDate(rrr);

        // console.log(dateFormat_qqq);
        // console.log(nlen);
        if (rrr < new Date()) {
          setQuesEnable(true);
        }

        //console.log(quesEndDate)
        var one_day = 1000 * 60 * 60 * 24;

        // To Calculate the result in milliseconds and then converting into days
        var Result = Math.round(rrr - new Date()) / one_day;

        const monTT = rrr.getMonth();
        // console.log(monTT);
        setGetMon(monTT);

        setnextDue(
          rrr.getDate() + "/" + (rrr.getMonth() + 1) + "/" + rrr.getFullYear()
        );

        // To remove the decimals from the (Result) resulting days value
        var Final_Result = Result.toFixed(0);

        setDaysYet(Final_Result);

        // console.log(response);

        // function getQuarter(cdate) {
        //     var d = new Date(parseInt(cdate));
        //     var quarter = Math.floor((d.getMonth() + 3) / 3);
        //     let quarterText = ["Jan - Mar", "Apr - Jun", "Jul - Sep", "Oct - Dec"];

        //     return quarterText[quarter - 1];
        // }

        let uiui = [
          ...new Set(
            response.map((ussr) =>
              new Date(parseInt(ussr.createdAt)).getFullYear()
            )
          ),
        ];

        // console.log(uiui);
        setYearsIn(uiui);

        let quarterText = [
          { qid: "0", qvalue: "Jan - Mar" },
          { qid: "1", qvalue: "Apr - Jun" },
          { qid: "2", qvalue: "Jul - Sep" },
          { qid: "3", qvalue: "Oct - Dec" },
        ];

        let quarStaByNum = [
          { sid: "0", svalue: "Available" },
          { sid: "1", svalue: "Unavailable" },
          { sid: "2", svalue: "Assessed" },
          { sid: "3", svalue: "Missed" },
        ];

        // console.log(quarterText[2])
        let jkj = null;
        let ioo = "";

        let assdMths = [];
        let asdYearMons = null;

        let quesAssSt = null;

        let nowMon = Math.floor((new Date().getMonth() + 3) / 3) - 1;

        let sttsOfQuars = [];

        let evertQuar = [];

        let FinalEveryQuar = [];

        let allQrs = [];
        let curQr = null;

        let zzz = 0;

        uiui.map((ere) =>
          ere === new Date().getFullYear()
            ? ere
            : uiui.push(new Date().getFullYear())
        );

        function removeDuplicates(arr) {
          return arr.filter((item, index) => arr.indexOf(item) === index);
        }
        uiui = removeDuplicates(uiui);
        setYearsIn(uiui);

        // console.log(uiui);

         /******** Generate quarters with data *********/

         function generateQuartersData(data) {
          const quartersData = [];
          const currentDate = new Date();
          const minYear = Math.min(...data.map(item => new Date(parseInt(item.createdAt)).getFullYear()));
          const currentYear = currentDate.getFullYear();
      
          // Loop through each year
          for (let year = minYear; year <= currentYear; year++) {
              // Loop through each quarter in the year
              for (let i = 0; i < 4; i++) {
                  const quarInd = i.toString();
                  const quarVal = getQuarterName(i);
                  const yrQind = year.toString() + quarInd;
      
                  // Find item for the current quarter and year
                  const item = data.find(item => {
                      const createdAt = new Date(parseInt(item.createdAt));
                      return createdAt.getFullYear() === year && Math.floor(createdAt.getMonth() / 3) === i;
                  });
      
                  // Determine status and scrdId based on conditions
                  let status = "Unavailable";
                  let scrdId = null;
                  if (item) {
                      status = "Assessed";
                      scrdId = item._id;
                  }
                  // if (year === currentYear && i === Math.floor(currentDate.getMonth() / 3)) {
                  //     const currentQuarter = quartersData.find(q => q.thisYear === currentYear && q.quarInd === quarInd);
                  //     if (currentQuarter && currentQuarter.status === "Unavailable") {
                  //         status = "Available";
                  //     }
                  // }
                  // // Find the index of the first assessed quarter with unavailable status
                  // const firstAssessedUnavailableIndex = quartersData.findIndex(q => q.status === "Assessed" && q.scrdId === null);
                  // console.log(firstAssessedUnavailableIndex);
                  // if (i === Math.floor(currentDate.getMonth() / 3) && quartersData.length > 0 && firstAssessedUnavailableIndex !== -1 && firstAssessedUnavailableIndex <= quartersData.length - 1) {
                  //     const missedItem = quartersData[firstAssessedUnavailableIndex];
                  //     if (missedItem.quarInd === quarInd && missedItem.status === "Unavailable") {
                  //         status = "Missed";
                  //     }
                  // }
      
                  // Create quarter data object and push to quartersData array
                  const quarterData = {
                      thisYear: year,
                      quarInd,
                      quarVal,
                      status,
                      scrdId,
                      yrQind
                  };
                  quartersData.push(quarterData);
              }
          }


          // Set Missed status
for (let i = 0; i < quartersData.length; i++) {
  const currentQuarter = quartersData[i];
  if (currentQuarter.status === "Unavailable" && currentQuarter.yrQind < currentYear.toString() + Math.floor(currentDate.getMonth() / 3).toString()) {
      const previousAssessedQuarter = quartersData.slice(0, i).reverse().find(q => q.status === "Assessed");
      if (previousAssessedQuarter && previousAssessedQuarter.yrQind < currentYear.toString() + Math.floor(currentDate.getMonth() / 3).toString()) {
          currentQuarter.status = "Missed";
      }
  }
}

        // Set Available status for the current quarter
        const currentQuarter = quartersData.find(q => q.thisYear === currentYear && q.quarInd === Math.floor(currentDate.getMonth() / 3).toString());
        if (currentQuarter && currentQuarter.status === "Unavailable") {
            currentQuarter.status = "Available";
        }
      
          return quartersData;
      }
      
      // Helper function to get quarter name
      function getQuarterName(quarterIndex) {
          switch (quarterIndex) {
              case 0:
                  return "Jan - Mar";
              case 1:
                  return "Apr - Jun";
              case 2:
                  return "Jul - Sep";
              case 3:
                  return "Oct - Dec";
              default:
                  return "";
          }
      }

        const resultQuarters = generateQuartersData(response);       

        /******** Generate quarters with data *********/

        assdMths.push(resultQuarters);
        // console.log(evertQuar);

        setQuesCals(resultQuarters);
      } else {
        setQuesEnable(true);
      }
    };

    fetchScoreCards();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //console.log(quesCals)

  const rows = [];

  quesss &&
    quesss.forEach((usr, index) => {
      rows.push({ id: usr._id });
    });

  function formatDate(date) {
    //var timeStamp = 1672928403951
    var dateFormat = new Date(parseInt(date));

    const PPdate =
      dateFormat.getDate() +
      "/" +
      (dateFormat.getMonth() + 1) +
      "/" +
      dateFormat.getFullYear();
    return PPdate;
  }

  // const stBkc = ["#90ee90", "#ccc", "#1c7b1d", "red"]
  const stBkc = ["#90ee90", "#ccc", "#93c47d", "#ffe599"];

  let jjj = "";

  let cbtnst = null;

  return (
    <>
      <Box
        elevation={3}
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/*
                <Grid container spacing={2} sx={{ justifyContent: "center", py: 3 }}>
                    <Grid item xs={12} sm={12} lg={4}>
                        <Card
                            style={{ backgroundColor: "#95D352" }}
                        >
                            <CardContent>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                >
                                    Questionnaire 1
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    Wellness
                                    <br />
                                    <br />
                                    <br />
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <CardActions>
                                    {quesEnable ?
                                        <Button href={'/Questionnaire/' + qtype} variant="outlined">Start Journey</Button>
                                        : <Typography>You have {daysYet} days to go</Typography>}
                                </CardActions>

                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>

                <Typography
                    component="h1"
                    variant="h4"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                >
                    Questionnaires List
                </Typography>
                <TableContainer>
                    <Table
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {quesss &&
                                quesss.map((usr, index) => (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={usr._id}
                                    >
                                        <TableCell>Wellness</TableCell>
                                        <TableCell>{formatDate(usr.createdAt)}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                component={Link}
                                                to={"/Report/" + usr._id}
                                            >
                                                View Report
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
*/}
      </Box>

      <Box
        elevation={3}
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto 50px",
          maxWidth: "500px",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: "center", py: 3, ml: 0 }}
        >
          {/* 
                    <Card
                        style={{
                            maxWidth: "500px",
                            marginTop: "15px",
                            minWidth: "100%",
                            textAlign: "center",
                            padding: "10px 15px 15px 15px"
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5">Assess Yourself</Typography>

                            {quesEnable ? (
                                <Link
                                    //to={'QuesList/' + curQues.questionnaire_name}
                                    to="/Questionnaire"

                                    style={{ textDecoration: "none" }}
                                >
                                    <CardActions style={{ justifyContent: "center" }}>
                                        <Button variant="contained">
                                            Click Here
                                        </Button>
                                    </CardActions>
                                </Link>
                            ) : (
                                <Typography sx={{ py: 2 }}>
                                    Your Next Assessment is due in {daysYet} Days.
                                </Typography>
                            )}

                        </CardContent>
                        <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ position: "relative", marginBottom: "40px" }}>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    style={{
                                        backgroundColor: "#9195A0",
                                        color: "#fff",
                                        border: "2px solid #9195A0",
                                        cursor: "default"
                                    }}
                                >
                                    2023
                                </Button>
                            </div>
                            <div style={{ position: "relative", marginBottom: "40px" }}>
                                <Button style={{ cursor: "default" }} variant="contained" size="small" disabled={getMon < 4 ? true : false}>
                                    Jan - Mar
                                </Button>
                                {getMon < 4 ? <Typography variant="p" style={textBBtn}>Assessed</Typography> : <Typography variant="p" style={textBBtn}>Available</Typography>}
                            </div>
                            <div style={{ position: "relative", marginBottom: "40px" }}>
                                <Button style={{ cursor: "default" }} variant="contained" size="small" disabled={getMon > 4 && getMon < 7 ? true : false}>
                                    Apr - Jun
                                </Button>
                                {getMon > 4 && getMon < 7 ? <Typography variant="p" style={textBBtn}>Assessed</Typography> : <Typography variant="p" style={textBBtn}>Available</Typography>}
                            </div>
                            <div style={{ position: "relative", marginBottom: "40px" }}>
                                <Button style={{ cursor: "default" }} variant="contained" size="small" disabled={getMon > 7 && getMon < 10 ? true : false}>
                                    Jul - Sep
                                </Button>
                                {getMon > 7 && getMon < 10 ? <Typography variant="p" style={textBBtn}>Assessed</Typography> : <Typography variant="p" style={textBBtn}>Available</Typography>}
                            </div>
                            <div style={{ position: "relative", marginBottom: "40px" }}>
                                <Button style={{ cursor: "default" }} variant="contained" size="small" disabled={getMon > 10 ? true : false}>
                                    Oct - Dec
                                </Button>
                                {getMon > 10 ? <Typography variant="p" style={textBBtn}>Assessed</Typography> : <Typography variant="p" style={textBBtn}>Available</Typography>}
                            </div>
                        </CardActions>
                    </Card>

                    <Card
                        style={{
                            maxWidth: "500px",
                            marginTop: "15px",
                            minWidth: "100%",
                            textAlign: "center",
                            padding: "10px 15px 15px 15px"
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5">Track Assessments</Typography>

                            <Typography sx={{ py: 2 }}>
                                Click on the date to view your assessment.
                            </Typography>

                        </CardContent>
                        <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>

                            {quesss && quesss.map((usr, index) => (

                                <div style={{ position: "relative", marginBottom: "40px" }}>
                                    <Button variant="outlined" size="small" component={Link} to={"/Report/" + usr._id}>
                                        {formatDate(usr.createdAt)}
                                    </Button>
                                </div>

                            ))}

                        </CardActions>
                    </Card>
                    */}

          <Card
            style={{
              maxWidth: "472px",
              marginTop: "15px",
              minWidth: "100%",
              textAlign: "center",
              padding: "10px 15px 15px 15px",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", paddingBottom: "15px" }}
              >
                Assess Yourself
              </Typography>

              {quesEnable ? (
                <Link
                  to={"/Questionnaire/" + curQues.ques_type}
                  //to="/Questionnaire"

                  style={{ textDecoration: "none" }}
                >
                  <CardActions style={{ justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "rgb(144, 238, 144)",
                        color: "#000",
                      }}
                    >
                      Click Here
                    </Button>
                  </CardActions>
                </Link>
              ) : (
                <Typography sx={{ py: 2 }}>
                  Your Next Assessment is due on {nextDue}
                </Typography>
              )}
            </CardContent>
            <>
              {yearsIn &&
                yearsIn.map((yi, yindex) => (
                  <CardActions
                    className="cardActionsy"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="yearccbtnpr">
                      <Button
                        variant="outlined"
                        size="small"
                        className="yearccbtn"
                        style={{
                          backgroundColor: "#9195A0",
                          color: "#fff",
                          border: "2px solid #9195A0",
                          cursor: "default",
                        }}
                      >
                        {yi}
                      </Button>
                    </div>
                    <div className="monthcn" style={{ display: "flex" }}>
                      {quesCals &&
                        quesCals.map((qcs, qcindex) =>
                          yi == qcs.thisYear
                            ? (qcs.status == "Available" ? (jjj = 0) : "",
                              qcs.status == "Unavailable" ? (jjj = 1) : "",
                              qcs.status == "Assessed" ? (jjj = 2) : "",
                              qcs.status == "Missed" ? (jjj = 3) : "",
                              (
                                <div
                                  className=""
                                  style={{
                                    position: "relative",
                                    marginBottom: "40px",
                                    padding: "0 5px 5px",
                                  }}
                                >
                                  <Button
                                    style={{
                                      cursor: "default",
                                      backgroundColor: stBkc[jjj],
                                      padding: "5px 10px",
                                      color: '#000'
                                    }}
                                    variant="contained"
                                    size="small"
                                    disabled={jjj == 1 ? true : false}
                                  >
                                    {qcs.quarVal}
                                  </Button>
                                  {jjj != 1 ? (
                                    <Typography variant="h6" style={textBBtn}>
                                      {qcs.status}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ))
                            : ""
                        )}
                    </div>
                  </CardActions>
                ))}
            </>
          </Card>

          <Card
            style={{
              maxWidth: "472px",
              marginTop: "15px",
              minWidth: "100%",
              textAlign: "center",
              padding: "10px 15px 15px 15px",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", paddingBottom: "15px" }}
              >
                Track Assessments
              </Typography>

              <Typography>
                Click on the date to view your assessment.
              </Typography>
            </CardContent>

            {yearsIn &&
              yearsIn.map((yi, yindex) => (
                <CardActions
                  className="cardActionsy"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="yearccbtnpr">
                    <Button
                      variant="outlined"
                      size="small"
                      className="yearccbtn"
                      style={{
                        backgroundColor: "#9195A0",
                        color: "#fff",
                        border: "2px solid #9195A0",
                        cursor: "default",
                      }}
                    >
                      {yi}
                    </Button>
                  </div>
                  <div className="monthcn" style={{ display: "flex" }}>
                    {quesCals &&
                      quesCals.map((qcs, qcindex) =>
                        yi == qcs.thisYear
                          ? (qcs.status == "Available" ? (jjj = 0) : "",
                            qcs.status == "Unavailable" ? (jjj = 1) : "",
                            qcs.status == "Assessed" ? (jjj = 2) : "",
                            qcs.status == "Missed" ? (jjj = 3) : "",
                            (
                              <div
                                style={{
                                  position: "relative",
                                  marginBottom: "40px",
                                  padding: "0 5px 5px",
                                }}
                              >
                                <Button
                                  className="btnassun"
                                  style={{
                                    backgroundColor: stBkc[jjj],
                                    padding: "5px 10px",
                                    color: '#000'
                                  }}
                                  variant="contained"
                                  size="small"
                                  component={jjj == 2 ? Link : Button}
                                  to={qtype === 'wellness' ? '/Report/' + qcs.scrdId : '/ReportOfAI/' + qcs.scrdId}
                                  disabled={jjj == 1 ? true : false}
                                >
                                  {qcs.quarVal}
                                </Button>
                                {jjj != 1 ? (
                                  <Typography variant="h6" style={textBBtn}>
                                    {qcs.status == "Assessed" ? "View" : ""}
                                    {qcs.status == "Available" ? "" : ""}
                                  </Typography>
                                ) : (
                                  ""
                                )}
                              </div>
                            ))
                          : ""
                      )}
                  </div>
                </CardActions>
              ))}
          </Card>
        </Grid>
      </Box>
    </>
  );
};

export default QuesList;
