import React from 'react'

import Typography from '@mui/material/Typography/Typography';
import Box from '@mui/material/Box';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwtDecode from "jwt-decode";

import { useGauth } from "./hooks/useGauth"

const Home = () => {

  const { gAuthlogin } = useGauth()

  const handleLogin = (credentialResponse) => {
    //console.log(credentialResponse)
    var userObject = jwtDecode(credentialResponse.credential);
    //let tmpgData = JSON.parse(userObject);

    //console.log(userObject)
    gAuthlogin(userObject.given_name, userObject.family_name, userObject.email, userObject.sub, userObject.picture)
  }
  
  const gCliId = process.env.REACT_APP_GOOGLE_CLIENT_ID

  return (
    <>
      <Box elevation={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', p: 5 }}>
        <Typography component="h1" variant="h4" align="center" color="textPrimary">Welcome to AYANA</Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>A DIGITAL HEALTH COMPANY</Typography>
        <img src='/ayanl.jpeg' alt='Ayana' title='Ayana' />
      </Box>

      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <GoogleOAuthProvider
          //clientId={"667259907049-pje0jb7rrpke9jtil6uefc46pk2lv4sl.apps.googleusercontent.com"}
          //clientId={"1050265659815-gd8vislgd70sr8c8sdbf94h58l5j3gg0.apps.googleusercontent.com"}
          clientId={gCliId}
        >
          <GoogleLogin
            width="260px"
            size="large"
            onSuccess={(credentialResponse) => {
              // console.log(credentialResponse);
              handleLogin(credentialResponse);
            }}
            onError={() => {
              console.log("Login Failed");
              //handleFailure();
            }}
          />
        </GoogleOAuthProvider>
      </Box>
    </>
  )
}

export default Home
